import { useEffect, useState } from 'react'
// import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import type { AppDispatch } from '../../redux/store'

import NormalLayout from 'components/Layouts/NormalLayout'
import useAuth from 'hooks/useAuth'
import BankLoginCard from './BankLoginCard'

const BankLogin = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleLoginBank = async (username: string) => {
    setLoading(true)
    try {
      await auth.login(username)
      navigate('/dashboard')
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (auth.isAuth()) {
      navigate('/dashboard')
    }
  }, [auth])

  return (
    <NormalLayout>
      <div className="flex gap-8 pt-8">
        <div className="w-[45%] pt-10">
          <span className="font-poppins text-4xl font-semibold text-blue1">
            Stablecoin Cross Border QR Payments
          </span>
        </div>
        <div className="w-[55%]">
          <BankLoginCard handleLoginBank={handleLoginBank} loading={loading} />
        </div>
      </div>
    </NormalLayout>
  )
}

export default BankLogin
