import Stepper from 'components/Stepper'

const PayLayout = (props) => {
  return (
    <div
      className={
        `mx-auto w-full px-5 pt-8 ` +
        (props.wide ? `max-w-[960px]` : `max-w-[540px]`)
      }
    >
      {props.children}
      <Stepper />
    </div>
  )
}

export default PayLayout
