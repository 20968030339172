import Arrow from 'assets/arrow.svg'
import VerticalSlider from 'assets/vertical-slider.png'
import Card from 'components/Card'
import PayLayout from 'components/Layouts/PayLayout'
import TransactionReceipt from 'components/TransactionReceipt'
import currencyFormatter from 'currency-formatter'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CompletedIcon from '../../assets/completed-icon.png'

const PaymentComplete = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  const [showReceipt, setShowReceipt] = useState(false)

  return (
    <PayLayout wide>
      <Card>
        <div className="p-8">
          <div className="flex gap-8 py-8">
            <div className="flex w-1/2 flex-col items-center justify-center">
              <img
                className="mx-auto"
                src={CompletedIcon}
                alt="Completed Icon"
              />
              <h1 className="py-4 font-workSans text-xl font-semibold text-blue1">
                Payment Successful
              </h1>
              <p>
                Exchanged exactly{' '}
                {currencyFormatter.format(auth.payAmount(), {
                  symbol: 'SGD',
                  format: '%v %s',
                })}{' '}
                for{' '}
                {currencyFormatter.format(auth.receivedAmount(), {
                  symbol: 'PHP',
                  format: '%v %s',
                })}
              </p>
              <p>
                To <b>Juan dela Cruz</b>
              </p>
            </div>
            <div className="flex w-1/2 gap-8">
              <div>
                <span>Today</span>
              </div>
              <div>
                <img className="w-[30px]" src={VerticalSlider} />
              </div>
              <div className="flex-grow text-blue1">
                <h3 className="font-bold">Converting XSGD to PHX</h3>
                <a
                  className="text-sm underline"
                  href={auth.payTxHashLinks()[0]}
                  target="_blank"
                >
                  View on Explorer
                </a>
                <h3 className="mt-20 font-bold">Sending PHX to UBX wallet</h3>
                <a
                  className="text-sm underline"
                  href={auth.payTxHashLinks()[1]}
                  target="_blank"
                >
                  View on Explorer
                </a>
                <h3 className="mt-20 font-bold">Redeem PHP from PHX</h3>
                <a
                  className="text-sm underline"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowReceipt(true)
                  }}
                >
                  View Transaction Details
                </a>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="mx-auto mt-12 flex w-1/3 items-center justify-center rounded-lg bg-blue1 py-3 font-workSans text-white hover:bg-blue2"
            onClick={() => navigate('/dashboard')}
          >
            Back to Wallet <img src={Arrow} className="pl-2" />
          </button>
        </div>
      </Card>

      <TransactionReceipt
        isVisible={showReceipt}
        onClose={() => setShowReceipt(false)}
        tx={{
          date: new Date().toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }),
          amount: currencyFormatter.format(auth.receivedAmount(), {
            symbol: 'PHP',
            format: '%v %s',
          }),
        }}
      />
    </PayLayout>
  )
}

export default PaymentComplete
