import useAuth from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import XaveLogo from '../../assets/logos/xave.svg'
import XCLabsLogo from '../../assets/logos/xclabs.svg'

const Header = () => {
  const navigate = useNavigate()
  const { isAuth, logout } = useAuth()

  const handleLogout = () => {
    logout()
    navigate('/')
  }

  return (
    <div className="h-auto border-b-gray-400 bg-white shadow-sm md:h-[80px]">
      <div className="m-auto flex h-full w-full flex-col items-center justify-between px-5 py-4 md:flex-row xl:px-10">
        <div className="cursor-pointer font-poppins text-xl font-extrabold">
          <a
            href="#"
            className="text-blue1"
            onClick={(e) => {
              e.preventDefault()
              navigate('/')
            }}
          >
            Stablecoin Cross Border
          </a>
          &nbsp;
          <a
            href="#"
            className="text-indigo1"
            onClick={(e) => {
              e.preventDefault()
              navigate('/')
            }}
          >
            QR Payments
          </a>
        </div>
        <div className="flex flex-col items-center gap-4 md:flex-row">
          <div className="font-workSans text-gray-400">
            Powered by Xave, Product of XC Labs
          </div>
          <img src={XaveLogo} alt="xave-logo" className="h-[25px]" />
          <img src={XCLabsLogo} alt="xclabs-logo" className="h-[42px]" />
          {isAuth() && (
            <button
              className="cursor-pointer rounded-full border border-blue1 px-4 py-1 font-workSans font-semibold text-blue1"
              onClick={handleLogout}
            >
              Logout
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
