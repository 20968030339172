import BaseModal from 'components/Modals/BaseModal'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { hashGenerator } from 'utils/hashGenerator'

const TransactionReceipt = (props) => {
  const auth = useAuth()
  const [isSender] = useState(localStorage.getItem('token') === 'sender')

  return (
    <BaseModal
      isVisible={props.isVisible}
      onClose={props.onClose}
      whiteCloseIcon={true}
    >
      <div className="-m-8 flex justify-between rounded-t-xl bg-orange-600 p-8 text-white">
        <div className="flex flex-col">
          <span className="font-bold">Reference Number</span>
          <span>{hashGenerator(props.tx?.date ?? '')}</span>
        </div>
        <div className="flex flex-col pr-12">
          <span>Transaction Date</span>
          <span>
            {isSender
              ? props.tx?.date
              : new Date(props.tx?.date ?? '').toLocaleString(
                  'en-US',
                  auth.formatOptions,
                )}
          </span>
        </div>
      </div>
      <div className="mt-12 flex gap-4 border-b border-dotted border-gray-300 py-4">
        <div className="w-[40%]">
          <span className="text-gray-400">Description</span>
        </div>
        <div className="flex w-[60%] flex-col text-gray-600">
          <span className="font-semibold">FUND TRANSFER</span>
          <span>UNIONBANK</span>
        </div>
      </div>
      <div className="flex gap-4 border-b border-dotted border-gray-300 py-4">
        <div className="w-[40%]">
          <span className="text-gray-400">From Account</span>
        </div>
        <div className="flex w-[60%] flex-col text-gray-600">
          <span className="font-semibold">UBX Entity</span>
          <span>**** **** 2222</span>
        </div>
      </div>
      <div className="flex gap-4 border-b border-dotted border-gray-300 py-4">
        <div className="w-[40%]">
          <span className="text-gray-400">To Account</span>
        </div>
        <div className="flex w-[60%] flex-col text-gray-600">
          <span className="font-semibold">Juan dela Cruz</span>
          <span>**** **** 1111</span>
        </div>
      </div>
      <div className="flex gap-4 border-b border-dotted border-gray-300 py-4">
        <div className="w-[40%]">
          <span className="text-gray-400">Amount</span>
        </div>
        <div className="flex w-[60%] flex-col text-gray-600">
          <span className="font-semibold">{props.tx?.amount ?? ''}</span>
        </div>
      </div>
      <div className="h-4 rounded-b-xl"></div>
    </BaseModal>
  )
}

export default TransactionReceipt
