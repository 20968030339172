import Loader from 'components/Loader'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import BaseModal from './BaseModal'

const PasswordModal = () => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const auth = useAuth()

  const handlePageAuth = () => {
    setLoading(true)

    if (password === process.env.REACT_APP_PASSWORD) {
      auth.enterPage()
      setLoading(true)
    } else {
      setError('Incorrect password')
      setLoading(false)
    }
  }

  return (
    <BaseModal
      isVisible={!Boolean(auth.isPageAccessible())}
      whiteCloseIcon={true}
    >
      <div className="text-center">
        <div className="text-2xl">
          Please enter correct password to get access.
        </div>
        <input
          type="password"
          className="mt-4 w-2/3 rounded-lg border border-black px-2 py-3 text-sm text-gray1 outline-none"
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value)
            setError('')
          }}
        />
        {error !== '' && <div className="text-red-400">{error}</div>}
        <button
          type="button"
          className="mt-4 flex w-full justify-center rounded-lg bg-blue1 py-3 font-workSans text-white hover:bg-blue2"
          onClick={handlePageAuth}
          disabled={loading}
        >
          {loading ? <Loader /> : 'Enter'}
        </button>
      </div>
    </BaseModal>
  )
}

export default PasswordModal
