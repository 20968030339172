import Loader from 'components/Loader'
import { useState } from 'react'
import BankLogo from '../../assets/bank-logo.png'

const BankLoginCard = ({ handleLoginBank, loading }) => {
  const [username, setUsername] = useState('')

  const login = () => {
    if (username !== '') {
      handleLoginBank(username)
    }
  }

  return (
    <div className="flex flex-col items-center rounded-xl border border-solid border-cardStroke bg-white p-10">
      <div className="flex items-center gap-10">
        <form className="flex w-[60%] flex-col gap-4">
          <input
            type="text"
            className="rounded bg-gray-50 py-2 px-4"
            placeholder="Username"
            onChange={(e) => setUsername(e.currentTarget.value)}
          />
          <input
            type="password"
            className="rounded bg-gray-50 py-2 px-4"
            placeholder="Password"
          />
        </form>
        <img className="w-[40%]" src={BankLogo} alt="bank-logo" />
      </div>
      <button
        type="button"
        className="mt-8 flex w-full justify-center rounded-lg bg-blue1 py-3 font-workSans text-white hover:bg-blue2"
        onClick={login}
        disabled={loading}
      >
        {loading ? <Loader /> : 'Login'}
      </button>
    </div>
  )
}

export default BankLoginCard
