interface CTAButtonProps {
  title: string
  imgSrc: string
  onClick: () => void
}

const CTAButton = ({ title, imgSrc, onClick }: CTAButtonProps) => {
  return (
    <a
      href="#"
      className="flex h-72 w-full flex-col items-center justify-center gap-4"
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
    >
      <img className="h-48" src={imgSrc} alt={`${title} icon`} />
      <span className="text-center font-workSans text-3xl text-blue1">
        {title}
      </span>
    </a>
  )
}

export default CTAButton
