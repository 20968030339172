export const Popover = ({ show, children }) => {
  if (!show) {
    return null
  }

  return (
    <div className="absolute top-1/2 left-1/2 z-50 mb-10 -translate-x-1/2 -translate-y-1/2 rounded border border-gray-300 bg-white p-2 text-sm text-gray-800 shadow-lg">
      {children}
    </div>
  )
}
