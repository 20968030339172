import Header from 'components/Header'
import PasswordModal from 'components/Modals/PasswordModal'
import { Route, Routes } from 'react-router-dom'
import BankLogin from './BankLogin'
import BankRecipient from './BankRecipient'
import CashIn from './CashIn'
import CashOut from './CashOut'
import Dashboard from './Dashboard'
import ErrorPage from './Error'
import Exchange from './Exchange'
import EnterPayment from './Exchange/EnterPayment'
import PayOrReceive from './PayOrReceive'
import PaymentComplete from './PayOrReceive/PaymentComplete'
import PayWithQr from './PayWithQr'
import PaymentMethod from './PaymentMethod'
import ReceivePayment from './ReceivePayment.tsx'
import SuccessCashIn from './Success/SuccessCashIn'
import SuccessCashOut from './Success/SuccessCashOut'
import SuccessExchange from './Success/SuccessExchange'
import Wallet from './Wallet'

function App() {
  return (
    <div className="m-0 min-h-screen w-screen bg-defaultBg p-0">
      <Header />

      <Routes>
        <Route path="/" element={<BankLogin />} />
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/topup">
          <Route path="*" element={<ErrorPage />} />
          <Route path="enter-amount" element={<CashIn />} />
          <Route path="select-method" element={<PaymentMethod />} />
          <Route path="cash-in" element={<SuccessCashIn />} />
        </Route>

        <Route path="/payment">
          <Route path="" element={<PayOrReceive />} />
          <Route path="pay-with-qr" element={<PayWithQr />} />
          <Route path="receive" element={<ReceivePayment />} />
          <Route path="enter-amount" element={<EnterPayment />} />
          <Route path="complete" element={<PaymentComplete />} />
        </Route>

        <Route path="/remit">
          <Route path="*" element={<ErrorPage />} />
          <Route path="bank-login" element={<BankLogin />} />
          <Route path="cash-in" element={<CashIn />} />
          <Route path="bank-recipient" element={<BankRecipient />} />
          <Route path="exchange" element={<Exchange />} />
          <Route path="cash-out" element={<CashOut />} />
          <Route path="success">
            <Route path="cash-in" element={<SuccessCashIn />} />
            <Route path="cash-out" element={<SuccessCashOut />} />
            <Route path="exchange" element={<SuccessExchange />} />
          </Route>
        </Route>
        <Route path="/wallet">
          <Route path="home" element={<Wallet />} />
        </Route>
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
      <PasswordModal />
    </div>
  )
}

export default App
