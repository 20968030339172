import Stepper from 'components/Stepper'

const TopUpLayout = (props) => {
  return (
    <div className="mx-auto w-full max-w-[540px] px-5 pt-8">
      {props.children}
      <Stepper />
    </div>
  )
}

export default TopUpLayout
