import { XIcon } from '@heroicons/react/solid'

const BaseModal = (props) => {
  return (
    <div
      className={
        `absolute top-0 left-0 z-10 h-screen w-screen items-center justify-center bg-black bg-opacity-40 ` +
        (props.isVisible ? `flex` : `hidden`)
      }
    >
      <div className="relative w-[480px] rounded-xl bg-white p-8">
        <button
          onClick={props.onClose}
          className={
            `absolute top-4 right-4 ` +
            (props.whiteCloseIcon ? `text-white` : `text-gray-600`)
          }
        >
          <XIcon className="h-6 w-6" />
        </button>
        {props.children}
      </div>
    </div>
  )
}

export default BaseModal
