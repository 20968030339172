import { ChevronDoubleLeftIcon } from '@heroicons/react/outline'
import CopyIcon from 'assets/copy-icon.svg'
import PHP from 'assets/currencies/php.svg'
import ReceiverQRCode from 'assets/receiver-qr-code.png'
import ScanQRIcon from 'assets/scan-qr-icon.png'
import UploadQRIcon from 'assets/upload-qr-icon.png'
import Card from 'components/Card'
import PayLayout from 'components/Layouts/PayLayout'
import Loader from 'components/Loader'
import { Popover } from 'components/Popover'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { shortenAddress } from 'utils/address'

enum PayWithQrStateEnum {
  Loading,
  QRSelection,
  PaymentDetails,
  PaymentInput,
  PaymentSuccess,
}

const PayWithQr = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [pageRender, setPageRender] = useState(PayWithQrStateEnum.QRSelection)
  const [paymentDetails, setPaymentDetails] = useState({
    nameOfBank: 'Unionbank',
    accountName: 'Juan dela Cruz',
    accountNumber: '**** **** 1111',
    walletAddress: '',
  })
  const [copied, setCopied] = useState(false)

  const handleCopy = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value)
      setCopied(true)
      setTimeout(() => setCopied(false), 1500) // Reset copied state after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  const handleUploadQR = async () => {
    setPageRender(PayWithQrStateEnum.Loading)
    try {
      const res = await fetch(
        'https://sc.identity-dev.ubx.ph/stablecoin-app/wallets/23Cf6D1Ed98C1DC01231F47b50b9402FF15C60cB',
      )
      const _res = await res.json()

      setPaymentDetails({
        ...paymentDetails,
        walletAddress: _res.data.address,
      })
      setPageRender(PayWithQrStateEnum.PaymentDetails)
      auth.setRecipientAddress(_res.data.address)
    } catch (error) {}
  }

  const handlePayNow = () => {
    auth.setRecipientAddress(paymentDetails.walletAddress)
    navigate('/payment/enter-amount')
  }

  return (
    <PayLayout>
      <Card>
        <div className="flex w-full items-center border-b p-5">
          <p className="font-workSans text-xl text-blue1">Payment Required</p>
        </div>
        <div className="p-8">
          {pageRender === PayWithQrStateEnum.Loading && (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          )}
          {pageRender === PayWithQrStateEnum.QRSelection && (
            <div className="flex justify-center gap-8">
              <div className="flex w-1/2 flex-col items-center">
                <img src={UploadQRIcon} alt={`${UploadQRIcon} icon`} />
                <label
                  htmlFor="uploadQR"
                  className="mt-12 flex w-full cursor-pointer justify-center rounded-lg bg-blue1 py-3 font-workSans text-white hover:bg-blue2"
                >
                  Upload QR
                </label>
                <input
                  id="uploadQR"
                  accept="image/*"
                  type="file"
                  multiple={false}
                  style={{ display: 'none' }}
                  onChange={handleUploadQR}
                />
              </div>
              <div className="flex w-1/2 flex-col items-center">
                <img src={ScanQRIcon} alt={`${ScanQRIcon} icon`} />
                <button
                  type="button"
                  className=" mt-12 flex w-full justify-center rounded-lg bg-gray-400 py-3 font-workSans text-white"
                  disabled={true}
                >
                  Scan QR
                </button>
              </div>
            </div>
          )}
          {pageRender === PayWithQrStateEnum.PaymentDetails && (
            <div className="flex flex-col gap-8 font-workSans">
              <div className="flex flex-col gap-4 text-sm">
                <div className="mb-4">
                  <span className="font-bold">
                    {paymentDetails.accountName}
                  </span>{' '}
                  is requesting payment from you.
                </div>

                <div className="flex items-end gap-8">
                  <div className="w-2/3">
                    <div className="flex items-center">
                      <img
                        className="rounded-full shadow-md shadow-gray-400"
                        src={PHP}
                        alt="PHP"
                      />
                      <span className="ml-2 text-[#404040]">PHP</span>
                    </div>
                    <div className="mt-2 flex flex-col gap-2">
                      <div className="flex justify-between">
                        <div className="text-[#404040]">Name of Bank</div>
                        <div className="font-bold">
                          {paymentDetails.nameOfBank}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="text-[#404040]">Account Name</div>
                        <div className="font-bold">
                          {paymentDetails.accountName}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="text-[#404040]">Account Number</div>
                        <div className="font-bold">
                          {paymentDetails.accountNumber}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="text-[#404040]">Wallet Address</div>
                        <div className="flex items-center font-bold">
                          {shortenAddress(paymentDetails.walletAddress)}
                          <img
                            className="ml-2 cursor-pointer"
                            src={CopyIcon}
                            alt={`${CopyIcon} icon`}
                            onClick={() =>
                              handleCopy(paymentDetails.walletAddress)
                            }
                          />
                        </div>
                        <Popover show={copied}>Copied!</Popover>
                      </div>
                    </div>
                  </div>
                  <img
                    className="w-1/3"
                    src={ReceiverQRCode}
                    alt={`${ReceiverQRCode} icon`}
                  />
                </div>
              </div>
              <button
                type="button"
                className="mx-auto flex w-1/2 justify-center rounded-lg bg-blue1 py-3 font-workSans text-white hover:bg-blue2"
                onClick={handlePayNow}
              >
                Pay Now
              </button>

              <a
                href="#"
                className="mt-2 flex items-center gap-1 text-blue1"
                onClick={(e) => {
                  e.preventDefault()
                  setPageRender(PayWithQrStateEnum.QRSelection)
                }}
              >
                <ChevronDoubleLeftIcon className="h-4 text-blue1" />
                Back
              </a>
            </div>
          )}
        </div>
      </Card>
    </PayLayout>
  )
}

export default PayWithQr
