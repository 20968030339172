import { ErrorMessage } from '@hookform/error-message'
import Card from 'components/Card'
import Dropdown from 'components/Dropdown'
import TopUpLayout from 'components/Layouts/TopUpLayout'
import Loader from 'components/Loader'
import { CURRENCIES } from 'constants/index'
import currencyFormatter from 'currency-formatter'
import useAuth from 'hooks/useAuth'
import { CashInSubmitInterface } from 'interfaces'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import { isBalanceEnough, isInputZero } from 'utils/inputValidations'

const CashIn = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const [currency, setCurrency] = useState<any>(CURRENCIES[0])
  const [balance] = useState(auth.bankBalance())

  useEffect(() => {
    if (!auth.isAuth()) navigate('/')
  }, [auth])

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    criteriaMode: 'all',
  })

  const onSubmit = async (data: CashInSubmitInterface) => {
    console.log(data)
    await handleCashIn(data)
  }

  const handleCashIn = async (data: CashInSubmitInterface) => {
    setLoading(true)

    await new Promise((resolve) => setTimeout(resolve, 1000))
    auth.setTopUpAmount(data.amount)

    setLoading(false)
    navigate('/topup/select-method')
  }

  const handleMax = () => {
    setValue('amount', balance)
  }

  const handleSelectCurrency = (currency) => {
    setCurrency(currency)
  }

  return (
    <TopUpLayout>
      <Card>
        <div className="flex w-full items-center border-b p-5">
          <p className="font-workSans text-xl text-blue1">Top Up</p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between gap-8 px-8 py-6">
            <div className="pt-12">
              <Dropdown
                name={'Select'}
                options={[CURRENCIES[0]]}
                selected={currency}
                setSelected={handleSelectCurrency}
              />
            </div>
            <div>
              <div className="text-md font-workSans font-semibold text-black1">
                Amount
              </div>
              <div className="flex justify-between rounded-lg bg-vanilla1 p-3 align-bottom">
                <div className="flex flex-col">
                  <div className="font-workSans text-xs">
                    BALANCE: {currencyFormatter.format(balance, {})}{' '}
                    {currency.currency}{' '}
                  </div>
                  <Controller
                    control={control}
                    name="amount"
                    rules={{
                      required: 'Amount is required.',
                      pattern: {
                        value: /^[0-9.]*$/,
                        message: 'Amount cannot be a negative value.',
                      },
                      min: {
                        value: 1,
                        message: 'Minimum amount is 1.',
                      },
                      validate: {
                        zeroValueInput: (value) =>
                          !isInputZero(value) || 'Amount cannot be zero.',
                        isBalanceEnough: (value) =>
                          isBalanceEnough(balance, value) ||
                          "You don't have enough balance.",
                      },
                    }}
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        decimalScale={2}
                        className="align-end mt-4 flex w-full border-none border-transparent bg-transparent font-roboto text-2xl focus:outline-none"
                      />
                    )}
                  />
                </div>
                <div className="flex self-end">
                  <button
                    type="button"
                    className="rounded-xl border border-blue1 py-1 px-2 text-xs text-blue1"
                    onClick={handleMax}
                  >
                    MAX
                  </button>
                </div>
              </div>

              <div className="mt-2 h-8">
                <ErrorMessage
                  errors={errors}
                  name="amount"
                  render={({ messages }) => {
                    console.log('messages', messages)
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            className="font-workSans text-xs text-red-600"
                            key={type}
                          >
                            {message}
                          </p>
                        ))
                      : null
                  }}
                />
              </div>

              <button
                // type="button"
                type="submit"
                className={`mt-2 flex h-12 w-full justify-center rounded-lg ${
                  !loading ? 'bg-blue1 hover:bg-blue2' : 'bg-gray1 opacity-50'
                }  py-3 font-workSans font-medium text-white`}
                disabled={loading}
              >
                {loading ? <Loader /> : 'Deposit'}
              </button>
            </div>
          </div>
        </form>
      </Card>
    </TopUpLayout>
  )
}

export default CashIn
