import {
  ChevronDoubleLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline'
import SGDLogo from 'assets/currencies/sgd.svg'
import BOC_logo from 'assets/methods/boc.jpg'
import DBS_logo from 'assets/methods/dbs.jpg'
import HSBC_logo from 'assets/methods/hsbc.jpg'
import MayBank_logo from 'assets/methods/maybank.jpg'
import OCBC_logo from 'assets/methods/ocbc.jpg'
import SCB_logo from 'assets/methods/scb.jpg'
import UOB_logo from 'assets/methods/uob.jpg'
import PayQRCode from 'assets/payment_qr.jpg'
import Card from 'components/Card'
import TopUpLayout from 'components/Layouts/TopUpLayout'
import Loader from 'components/Loader'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const PaymentMethod = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const [selectedMethod, setSelectedMethod] = useState<string | undefined>(
    undefined,
  )
  const [isVerifying, setIsVerifying] = useState(false)

  const [payMethods] = useState([
    { name: 'UOB', imgSrc: UOB_logo },
    { name: 'OCBC', imgSrc: OCBC_logo },
    { name: 'DBS', imgSrc: DBS_logo },
    { name: 'SCB', imgSrc: SCB_logo },
    { name: 'MayBank', imgSrc: MayBank_logo },
    { name: 'BOC', imgSrc: BOC_logo },
    { name: 'HSBC', imgSrc: HSBC_logo },
  ])

  const handleBackClick = () => {
    if (selectedMethod) {
      setSelectedMethod(undefined)
    } else {
      navigate('/topup/enter-amount')
    }
  }

  const handleVerifyClick = async () => {
    setIsVerifying(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    auth.performTopUp()
    setIsVerifying(false)
    navigate('/topup/cash-in')
  }

  return (
    <TopUpLayout>
      <Card>
        <div className="flex w-full items-center border-b p-5">
          <p className="font-workSans text-xl text-blue1">Top Up</p>
        </div>
        <div className="p-8">
          {selectedMethod ? (
            <>
              <div className="flex gap-8">
                <div>
                  <h3 className="font-semibold">Amount</h3>
                  <div className="mt-2 flex items-center justify-between gap-2 rounded-xl bg-gray-50 p-4">
                    <img
                      className="rounded-full shadow-md shadow-gray-400"
                      src={SGDLogo}
                      alt="SGD"
                    />
                    <span>SGD</span>
                    <span className="flex-grow text-right text-2xl">
                      {auth.topUpAmount()}
                    </span>
                  </div>
                  <p className="mt-8 text-sm">
                    Open your Banking Mobile app and Scan this QR code to pay
                  </p>
                </div>
                <div>
                  <img src={PayQRCode} alt="1234567890" />
                </div>
              </div>
              <div className="mt-8 text-center">
                <a
                  href="#"
                  className="mx-auto flex w-1/2 justify-center rounded-lg bg-blue1 px-4 py-2 font-workSans font-medium text-white"
                  onClick={(e) => {
                    e.preventDefault()
                    handleVerifyClick()
                  }}
                >
                  {isVerifying ? <Loader /> : 'Verify Payment'}
                </a>
              </div>
            </>
          ) : (
            <>
              <div>Select your bank to start linking your account</div>
              {payMethods.map((method, index) => (
                <a
                  href="#"
                  className="flex items-center gap-4 border-b border-gray-100 px-2 py-4 hover:bg-gray-50"
                  onClick={(end) => {
                    end.preventDefault()
                    setSelectedMethod(method.name)
                  }}
                >
                  <img
                    className="w-[24px]"
                    src={method.imgSrc}
                    alt={method.name}
                  />
                  <span className="flex-grow">{method.name}</span>
                  <ChevronRightIcon className="h-4 text-gray-400" />
                </a>
              ))}
            </>
          )}
          <a
            href="#"
            className="mt-8 flex items-center gap-1 text-blue1"
            onClick={(e) => {
              e.preventDefault()
              handleBackClick()
            }}
          >
            <ChevronDoubleLeftIcon className="h-4 text-blue1" />
            Back
          </a>
        </div>
      </Card>
    </TopUpLayout>
  )
}

export default PaymentMethod
