import { TrashIcon } from '@heroicons/react/solid'
import CardV2 from 'components/Card/CardV2'
import DashboardLayout from 'components/Layouts/DashboardLayout'
import TransactionReceipt from 'components/TransactionReceipt'
import currencyFormatter from 'currency-formatter'
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { extractNumber } from 'utils/extractNumber'
import PayIcon from '../../assets/pay-icon.png'
import TopUpIcon from '../../assets/topup-icon.png'
import CTAButton from '../../components/Buttons/CTAButton'

interface HistoryItem {
  date: string
  type: string
  amount: string
  amountReceived: string
}

const Dashboard = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [isSender] = useState(localStorage.getItem('token') === 'sender')
  const [history, setHistory] = useState<HistoryItem[]>(
    isSender ? auth.history() : [],
  )
  const [selectedTx, setSelectedTx] = useState<
    { date: string; amount: string } | undefined
  >(undefined)
  const [pollCtr, setPollCtr] = useState(0)

  const calcReceiverBalance = (history: any) => {
    let total: number = 0

    history.forEach((item) => {
      if (item.type === 'Received' && item.amountReceived) {
        const extractedNumber = extractNumber(item.amountReceived)
        if (extractedNumber !== null) {
          total += extractedNumber
        }
      }
    })

    auth.setReceiverBalance(total)
  }

  useEffect(() => {
    if (!auth.isAuth()) {
      navigate('/')
    }
  }, [auth])

  useEffect(() => {
    if (isSender)
      return // fetch history for receiver only
      // Polling every 2 seconds
    ;(async () => {
      try {
        const res = await fetch(
          'https://cllmkj2upa.execute-api.us-east-1.amazonaws.com/receiver-history',
        )
        const data = await res.json()
        const sortedData = data.sort((a: any, b: any) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime()
        })
        calcReceiverBalance(sortedData)
        setHistory(sortedData)
      } catch (e) {
        console.error(e)
      }
    })()

    const timer = setTimeout(() => {
      setPollCtr(pollCtr + 1)
    }, 2000)

    return () => clearTimeout(timer)
  }, [pollCtr])

  const clearHistory = async () => {
    try {
      await fetch(
        'https://cllmkj2upa.execute-api.us-east-1.amazonaws.com/receiver-history',
        { method: 'DELETE' },
      )
      setHistory([])
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <DashboardLayout>
      <h1 className="mb-8 font-poppins text-4xl font-semibold text-blue1">
        My Wallet
      </h1>
      <div className="gap-8 md:flex">
        <div className="flex flex-col gap-8 md:w-[60%]">
          <div className="flex w-full flex-col gap-8">
            <CardV2 title="Your Balance">
              <div className="mt-4 w-full rounded-xl bg-gray-50 p-4 font-poppins text-5xl font-bold">
                {currencyFormatter.format(auth.balance(), {
                  symbol: isSender ? 'SGD' : 'PHP',
                  format: '%v %s',
                })}
              </div>
            </CardV2>
          </div>
          <div className="flex justify-between gap-8">
            <div className="flex w-full flex-col gap-8 md:w-[50%]">
              <CardV2 centered>
                <CTAButton
                  title="Top Up"
                  imgSrc={TopUpIcon}
                  onClick={() => navigate('/topup/enter-amount')}
                />
              </CardV2>
            </div>
            <div className="flex w-full flex-col gap-8 md:w-[50%]">
              <CardV2 centered>
                <CTAButton
                  title="Pay with QR/Receive Payment"
                  imgSrc={PayIcon}
                  onClick={() => {
                    navigate('/payment')
                  }}
                />
              </CardV2>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-8 md:w-[40%]">
          <CardV2 title="Your History">
            <div className="mt-8 flex flex-col gap-2 text-[#5E5E5E]">
              {history.length === 0 && <span>No records to display</span>}
              {history.map((history, key) => {
                return (
                  <a
                    key={key}
                    href="#"
                    className="flex justify-between gap-2 text-base"
                    onClick={(e) => {
                      e.preventDefault()
                      if (
                        history.type === 'Payment' ||
                        history.type === 'Received'
                      ) {
                        setSelectedTx({
                          date: history.date,
                          amount:
                            history.amountReceived !== ''
                              ? history.amountReceived
                              : history.amount,
                        })
                      }
                    }}
                  >
                    <div className="w-1/3">
                      {isSender
                        ? history.date
                        : new Date(history.date).toLocaleString(
                            'en-US',
                            auth.formatOptions,
                          )}
                    </div>
                    <div className="w-1/3">{history.type}</div>
                    <div className="w-1/3 text-right">{history.amount}</div>
                  </a>
                )
              })}
              {!isSender && (
                <button
                  onClick={clearHistory}
                  className="mt-8 flex items-center gap-1 text-red-600"
                >
                  <TrashIcon className="h-4" /> Clear
                </button>
              )}
            </div>
          </CardV2>
        </div>
      </div>

      <TransactionReceipt
        isVisible={selectedTx !== undefined}
        onClose={() => setSelectedTx(undefined)}
        tx={selectedTx}
      />
    </DashboardLayout>
  )
}

export default Dashboard
