import CTAButton from 'components/Buttons/CTAButton'
import CardV2 from 'components/Card/CardV2'
import NormalLayout from 'components/Layouts/NormalLayout'
import { useNavigate } from 'react-router-dom'
import PayWithQRIcon from '../../assets/pay-with-qr-icon.png'
import ReceiveIcon from '../../assets/receive-icon.png'

const PayOrReceive = () => {
  const navigate = useNavigate()
  return (
    <NormalLayout>
      <div className="flex justify-center gap-8">
        <div className="flex w-full flex-col gap-8 md:w-[40%]">
          <CardV2 centered>
            <CTAButton
              title="Pay with QR"
              imgSrc={PayWithQRIcon}
              onClick={() => navigate('/payment/pay-with-qr')}
            />
          </CardV2>
        </div>
        <div className="flex w-full flex-col gap-8 md:w-[40%]">
          <CardV2 centered>
            <CTAButton
              title="Receive Payment"
              imgSrc={ReceiveIcon}
              onClick={() => {
                navigate('/payment/receive')
              }}
            />
          </CardV2>
        </div>
      </div>
    </NormalLayout>
  )
}

export default PayOrReceive
