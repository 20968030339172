import Arrow from 'assets/arrow.svg'
import Card from 'components/Card'
import NormalLayout from 'components/Layouts/NormalLayout'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ReceiverQRCode from '../../assets/receiver-qr-code.png'
import SaveIcon from '../../assets/save-icon.svg'
import SendIcon from '../../assets/send-icon.svg'

const ReceivePayment = () => {
  const navigate = useNavigate()
  const qrRef = useRef(null)

  const handleSaveQR = () => {
    const link = document.createElement('a')
    link.href = ReceiverQRCode
    link.download = 'ReceiverQRCode.jpg'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <NormalLayout>
      <div className="flex justify-center">
        <div className="md:w-[60%]">
          <Card>
            <div className="flex w-full items-center border-b p-5">
              <p className="font-workSans text-xl text-blue1">My QR Code</p>
            </div>
            <div className="flex flex-col items-center gap-8 p-8">
              <div className="flex items-center gap-8">
                <img
                  className="w-1/2"
                  src={ReceiverQRCode}
                  alt={`${ReceiverQRCode} icon`}
                  ref={qrRef}
                />
                <div className="flex w-1/2 flex-col gap-4">
                  <button
                    type="button"
                    className="flex w-full justify-center rounded-full border border-gray-400 bg-white py-2 font-workSans font-semibold text-gray-400"
                    disabled={true}
                  >
                    Send QR <img src={SendIcon} className="pl-2" />
                  </button>
                  <button
                    type="button"
                    className="flex w-full justify-center rounded-full border border-blue1 bg-white py-2 font-workSans font-semibold text-blue1 hover:bg-[#B3D6F9]"
                    onClick={handleSaveQR}
                  >
                    Save QR <img src={SaveIcon} className="pl-2" />
                  </button>
                </div>
              </div>
              <button
                type="button"
                className="flex w-1/2 items-center justify-center rounded-lg bg-blue1 py-3 font-workSans text-white hover:bg-blue2"
                onClick={() => navigate('/dashboard')}
              >
                Back to Wallet <img src={Arrow} className="pl-2" />
              </button>
            </div>
          </Card>
        </div>
      </div>
    </NormalLayout>
  )
}

export default ReceivePayment
