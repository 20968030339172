import { ReactNode } from 'react'

interface CardV2Props {
  title?: string
  centered?: boolean
  children: ReactNode
}

const CardV2 = ({ title, centered, children }: CardV2Props) => {
  return (
    <div
      className={
        `w-full rounded-xl border border-solid border-cardStroke bg-white p-8 ` +
        (centered ? `flex justify-center ` : ``)
      }
    >
      {title && <div className="font-poppins text-3xl text-blue1">{title}</div>}
      {children}
    </div>
  )
}

export default CardV2
