import { ErrorMessage } from '@hookform/error-message'
import PHPLogo from 'assets/currencies/php.svg'
import SGDLogo from 'assets/currencies/sgd.svg'
import LoadingIcon from 'assets/loading.svg'
import Card from 'components/Card'
import PayLayout from 'components/Layouts/PayLayout'
import Loader from 'components/Loader'
import currencyFormatter from 'currency-formatter'
import { parseUnits } from 'ethers/lib/utils'
import useAuth from 'hooks/useAuth'
import debounce from 'lodash.debounce'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import { isInputZero } from 'utils/inputValidations'

const EnterPayment = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentOngoing, setIsPaymentOngoing] = useState(false)
  const [amountOut, setAmountOut] = useState(0)
  const [rate, setRate] = useState(0)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [buttonTitle, setButtonTitle] = useState('Exchange')

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    register,
    reset,
    getValues,
  } = useForm({
    criteriaMode: 'all',
  })

  const handleInputChange = debounce(async (type: string, input: string) => {
    setButtonTitle('Exchange')
    setIsButtonEnabled(false)

    if (input === '') {
      setAmountOut(0)
      setRate(0)
      return
    }

    setIsLoading(true)

    setValue('sellAmount', input)
    const inputBN = parseUnits(input, 6)

    try {
      const res = await fetch(
        'https://6g1crw67n6.execute-api.us-east-1.amazonaws.com/dev/demo/view-vault-swap',
        {
          method: 'POST',
          body: JSON.stringify({
            amountIn: inputBN.toString(),
          }),
        },
      )

      const resJson = await res.json()

      if (resJson.data) {
        console.log('quote result:', resJson.data)
        const amountIn = parseFloat(input)
        const amountOut = parseFloat(resJson.data.amountOut)
        const balance = auth.balance()

        setAmountOut(amountOut)
        setRate(amountOut / amountIn)

        if (balance >= amountIn) {
          setButtonTitle('Exchange')
          setIsButtonEnabled(true)
        } else {
          setButtonTitle('Insufficient balance')
        }
      } else {
        setAmountOut(0)
        setRate(0)
        setButtonTitle('Unable to get quote')
      }
    } catch (error) {
      console.error('quote error:', error)
      setAmountOut(0)
      setRate(0)
      setButtonTitle('Unable to get quote')
    }

    setIsLoading(false)
  }, 400)

  const handleExchangeClick = async () => {
    setIsPaymentOngoing(true)

    try {
      const [amountIn] = getValues(['sellAmount'])
      const inputBN = parseUnits(amountIn, 6)

      // STEP 1: Swap XSGD -> PHX
      const res = await fetch(
        'https://6g1crw67n6.execute-api.us-east-1.amazonaws.com/dev/demo/vault-swap',
        {
          method: 'POST',
          body: JSON.stringify({
            amountIn: inputBN.toString(),
          }),
        },
      )

      const resJson = await res.json()
      console.log('swap result:', resJson)

      if (!resJson.data) {
        setIsPaymentOngoing(false)
        setButtonTitle('Unable to complete exchange')
        return
      }

      const swapTxHashLink = resJson.data.explorerView
      const amountOut = parseFloat(resJson.data.amountOut)
      const amountOutBN = parseUnits(amountOut.toString(), 18)

      // Update states on useAuth
      auth.performPay(amountIn, amountOut)
      auth.setReceivedAmount(amountOut.toString())

      // STEP 2: Transfer PHX to UBX wallet
      const res2 = await fetch(
        'https://6g1crw67n6.execute-api.us-east-1.amazonaws.com/dev/demo/transfer-ubx-wallet',
        {
          method: 'POST',
          body: JSON.stringify({
            amount: amountOutBN.toString(),
            walletAddress: auth.recipientAddress(),
          }),
        },
      )

      const resJson2 = await res2.json()
      console.log('transfer result:', resJson2)

      if (!resJson2.data) {
        setIsPaymentOngoing(false)
        setButtonTitle('Unable to complete exchange')
        return
      }

      const transferTxHashLink = resJson2.data.explorerView

      auth.setPayTxHashLinks(swapTxHashLink, transferTxHashLink)

      // @temp: Update receiver history
      const amtOutFormatted = currencyFormatter.format(amountOut, {
        symbol: 'PHP',
        format: '%v %s',
      })
      await fetch(
        'https://cllmkj2upa.execute-api.us-east-1.amazonaws.com/receiver-history',
        {
          method: 'POST',
          body: JSON.stringify({
            date: new Date().toISOString(),
            type: 'Received',
            amount: amtOutFormatted,
            amountReceived: amtOutFormatted,
          }),
        },
      )

      navigate('/payment/complete')
    } catch (error) {
      console.error('quote error:', error)
      setIsPaymentOngoing(false)
    }
  }

  return (
    <PayLayout>
      <Card>
        <div className="flex w-full items-center border-b p-5">
          <p className="font-workSans text-xl text-blue1">Exchange Currency</p>
        </div>
        <div className="p-8">
          <p className="pb-2 text-gray-600">You send exactly</p>
          <div className="flex items-center justify-between gap-2 rounded-xl border border-gray-200 bg-vanilla1 p-4">
            <Controller
              control={control}
              name="sellAmount"
              rules={{
                required: 'Amount is required.',
                pattern: {
                  value: /^[0-9.]*$/,
                  message: 'Amount cannot be a negative value.',
                },
                min: {
                  value: 1,
                  message: 'Minimum amount is 1.',
                },
                validate: {
                  zeroValueInput: (value) =>
                    !isInputZero(value) || 'Amount cannot be zero.',
                },
                onChange: (e) => {
                  handleInputChange('sell', e.target.value)
                },
              }}
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  decimalScale={2}
                  className="flex-grow bg-transparent text-xl focus:outline-none"
                />
              )}
            />
            <img
              className="rounded-full shadow-md shadow-gray-400"
              src={SGDLogo}
              alt="SGD"
            />
            <span>SGD</span>
          </div>
          <div className="mt-1 text-right text-xs text-gray-500">
            Your balance:{' '}
            {currencyFormatter.format(auth.balance(), {
              symbol: 'SGD',
              format: '%v %s',
            })}{' '}
          </div>
          <div className="mt-2">
            <ErrorMessage
              errors={errors}
              name="sellAmount"
              render={({ messages }) => {
                console.log('messages', messages)
                return messages
                  ? Object.entries(messages).map(([type, message]) => (
                      <p
                        className="font-workSans text-xs text-red-600"
                        key={type}
                      >
                        {message}
                      </p>
                    ))
                  : errors.sellAmount && (
                      <p className="font-workSans text-xs text-red-600">
                        {errors.sellAmount.message as any}
                      </p>
                    )
              }}
            />
          </div>
          <p className="pb-2 text-gray-600">Recipient gets</p>
          <div className="flex items-center justify-between gap-2 rounded-xl border border-gray-200 bg-vanilla1 p-4">
            <NumberFormat
              value={amountOut}
              decimalScale={2}
              className="flex-grow bg-transparent text-xl focus:outline-none"
              disabled
            />
            <img
              className="rounded-full shadow-md shadow-gray-400"
              src={PHPLogo}
              alt="PHP"
            />
            <span>PHP</span>
          </div>

          <div className="mt-8 flex items-center justify-between text-sm">
            <span>Exchange Rate</span>
            {isLoading ? (
              <img
                src={LoadingIcon}
                className="mr-4 h-5 animate-spin text-gray-200"
              />
            ) : (
              <span className="font-bold">
                1 SGD ={' '}
                {currencyFormatter.format(rate, {
                  symbol: 'PHP',
                  format: '%v %s',
                })}
              </span>
            )}
          </div>

          <div className="mt-8">
            <button
              className={
                `mx-auto flex h-11 w-full items-center justify-center rounded-lg  px-4 py-2 font-workSans font-medium text-white ` +
                (isButtonEnabled ? `bg-blue1 ` : `bg-gray3 `)
              }
              onClick={(e) => {
                e.preventDefault()
                handleExchangeClick()
              }}
              disabled={!isButtonEnabled}
            >
              {isLoading || isPaymentOngoing ? <Loader /> : buttonTitle}
            </button>
          </div>
        </div>
      </Card>
    </PayLayout>
  )
}

export default EnterPayment
