const useAuth = () => {
  const formatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }

  const _keyPrefix = () => {
    const token = localStorage.getItem('token')
    return token === 'sender' ? 'sender_' : 'receiver_'
  }

  const isAuth = () => {
    return localStorage.getItem('token') ? true : false
  }

  const isPageAccessible = () => {
    return localStorage.getItem('isPageAccessible') === 'true' ? true : false
  }

  const enterPage = () => {
    localStorage.setItem(`isPageAccessible`, 'true')
  }

  const balance = () => {
    const bal = localStorage.getItem(`${_keyPrefix()}balance`)
    return bal ? parseFloat(bal) : 0
  }

  const bankBalance = () => {
    const bal = localStorage.getItem(`sender_bank_balance`)
    if (!bal) {
      localStorage.setItem(`sender_bank_balance`, '120420')
      return 120420
    } else {
      return parseFloat(bal)
    }
  }

  const login = async (username: string) => {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    const isSender = username === 'sender'
    localStorage.setItem('token', isSender ? 'sender' : 'receiver')
  }

  const topUpAmount = () => {
    const amt = localStorage.getItem(`topup_amount`)
    return amt ? parseFloat(amt) : 0
  }

  const setTopUpAmount = (amt: string) => {
    localStorage.setItem(`topup_amount`, amt)
  }

  const payAmount = () => {
    const amt = localStorage.getItem(`pay_amount`)
    return amt ? parseFloat(amt) : 0
  }

  const recipientAddress = () => {
    return localStorage.getItem(`recipient_add`)
  }

  const setRecipientAddress = (addr: string) => {
    localStorage.setItem(`recipient_add`, addr)
  }

  const performTopUp = () => {
    const bal = balance()
    const newBal = bal + topUpAmount()
    localStorage.setItem(`sender_balance`, `${newBal}`)

    const bankBal = bankBalance()
    const newBankBal = bankBal - topUpAmount()
    localStorage.setItem(`sender_bank_balance`, `${newBankBal}`)

    _addToHistory('sender', {
      type: 'Top Up',
      amount: `+${topUpAmount().toString()} SGD`,
      date: new Date().toLocaleString('en-US', formatOptions),
      amountReceived: undefined,
    })
  }

  const performPay = (amountPaid: number, amountReceived: number) => {
    localStorage.setItem(`pay_amount`, amountPaid.toString())

    const bal = balance()
    const newBal = bal - amountPaid
    localStorage.setItem(`sender_balance`, `${newBal}`)

    _addToHistory('sender', {
      type: 'Payment',
      amount: `-${amountPaid.toString()} SGD`,
      date: new Date().toLocaleString('en-US', formatOptions),
      amountReceived: `${amountReceived.toString()} PHP`,
    })

    _addToHistory('receiver', {
      type: 'Received',
      amount: `+${amountReceived.toString()} PHP`,
      date: new Date().toLocaleString('en-US', formatOptions),
      amountReceived: undefined,
    })
  }

  const setReceiverBalance = (amountReceived: number) => {
    localStorage.setItem(`receiver_balance`, `${amountReceived.toString()} PHP`)
  }

  const payTxHashLinks = () => {
    const links = localStorage.getItem(`tx_hash_links`)
    return links ? links.split('|') : ['#', '#']
  }

  const setPayTxHashLinks = (
    swapTxHashLink: string,
    transferTxHashLink: string,
  ) => {
    localStorage.setItem(
      `tx_hash_links`,
      [swapTxHashLink, transferTxHashLink].join('|'),
    )
  }

  const receivedAmount = () => {
    const amt = localStorage.getItem(`received_amount`)
    return amt ? parseFloat(amt) : 0
  }

  const setReceivedAmount = (amt: string) => {
    localStorage.setItem(`received_amount`, amt)
  }

  const history = () => {
    const rawHistory = localStorage.getItem(`${_keyPrefix()}history`)
    if (!rawHistory) return []

    const history = rawHistory
      .split('|')
      .map((item) => {
        const [type, amount, date, amountReceived] = item.split('/')
        return { type, amount, date, amountReceived }
      })
      .reverse()

    return history
  }

  const _addToHistory = (
    prefix: string,
    item: {
      type: string
      amount: string
      date: string
      amountReceived: string | undefined
    },
  ) => {
    const rawHistory = localStorage.getItem(`${prefix}_history`)
    if (!rawHistory) {
      localStorage.setItem(
        `${prefix}_history`,
        `${item.type}/${item.amount}/${item.date}/${item.amountReceived ?? ''}`,
      )
    } else {
      localStorage.setItem(
        `${prefix}_history`,
        `${rawHistory}|${item.type}/${item.amount}/${item.date}/${
          item.amountReceived ?? ''
        }`,
      )
    }
  }

  const logout = () => {
    localStorage.clear()
  }

  return {
    isAuth,
    balance,
    bankBalance,
    login,
    topUpAmount,
    setTopUpAmount,
    performTopUp,
    payAmount,
    recipientAddress,
    setRecipientAddress,
    performPay,
    payTxHashLinks,
    setPayTxHashLinks,
    receivedAmount,
    setReceivedAmount,
    history,
    logout,
    setReceiverBalance,
    isPageAccessible,
    enterPage,
    formatOptions,
  }
}

export default useAuth
