import currencyFormatter from 'currency-formatter'
import { useNavigate } from 'react-router-dom'

import Card from 'components/Card'
import Arrow from '../../assets/arrow.svg'
// import SuccessCashInIcon from '../../assets/safe 1.svg'
import SuccessCashInIcon from 'assets/cash_in.png'
import TopUpLayout from 'components/Layouts/TopUpLayout'
import useAuth from 'hooks/useAuth'
import { useEffect } from 'react'

const SuccessCashIn = () => {
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    return () => {
      // reset topup amount on exit
      auth.setTopUpAmount('0')
    }
  }, [auth])

  return (
    <TopUpLayout>
      <Card>
        <div className="flex flex-col items-center py-4">
          <div>
            <img src={SuccessCashInIcon} alt="success-icon" />
          </div>
          <div className="py-2 font-workSans text-xl font-semibold text-blue1">
            Cash-in Successful!
          </div>
          <div className="font-workSans text-sm">
            Cash-in exactly{' '}
            {currencyFormatter.format(auth.topUpAmount(), {
              symbol: 'SGD',
              format: '%v %s',
            })}
          </div>
          <div className="py-8">
            <button
              className="flex items-center rounded-md bg-blue1 py-2 px-4 font-medium text-white"
              onClick={() => navigate('/dashboard')}
            >
              DONE <img src={Arrow} className="pl-2" />
            </button>
          </div>
        </div>
      </Card>
    </TopUpLayout>
  )
}

export default SuccessCashIn
